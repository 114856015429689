var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"toolbarItemRef",class:[
    'toolbar-item-container',
    !_setup.isPC && 'toolbar-item-container-h5',
    _setup.isUniFrameWork && 'toolbar-item-container-uni',
  ]},[_c('div',{class:[
      'toolbar-item-container-icon',
      !_setup.isPC && 'toolbar-item-container-h5-icon',
      _setup.isUniFrameWork && 'toolbar-item-container-uni-icon',
    ],on:{"click":_setup.toggleToolbarItem}},[_c(_setup.Icon,{staticClass:"icon",attrs:{"file":_setup.props.iconFile,"width":_setup.props.iconWidth,"height":_setup.props.iconHeight}})],1),(_setup.isUniFrameWork)?_c('div',{class:['toolbar-item-container-uni-title']},[_vm._v(" "+_vm._s(_setup.props.title)+" ")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_setup.showDialog),expression:"showDialog"}],ref:"dialogRef",class:[
      'toolbar-item-container-dialog',
      !_setup.isPC && 'toolbar-item-container-h5-dialog',
      _setup.isUniFrameWork && 'toolbar-item-container-uni-dialog',
    ]},[(_setup.props.needBottomPopup && !_setup.isPC)?_c(_setup.BottomPopup,{attrs:{"show":_setup.showDialog},on:{"onClose":_setup.onPopupClose}},[_vm._t("default")],2):_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div :class="['home', isH5 && 'home-h5']" :id="isPC ? 'preloadedImages' : ''">
    <div :class="['home-container', isMenuShow && 'menu-expand']">
      <div class="home-main">
        <div class="home-TUIKit">
          <div  class="home-TUIKit-navbar">
            <NavBar
                :isAdmin="isAdmin"
                :currentNavBar="currentNavBar"
                :isSettingShow="isSettingShow"
                @toggleSettingShow="toggleSettingShow"
                @switchNavBar="switchNavBar">
              <template v-slot:profile>
                <Profile display-type="profile"></Profile>
              </template>
              <template v-slot:setting>
                <Profile
                    display-type="setting"
                    :showSetting="isSettingShow"
                    @toggleSettingShow="toggleSettingShow"
                ></Profile>
              </template>
            </NavBar>
          </div>
          <div v-if="isPC" class="home-TUIKit-main">
            <div v-show="currentNavBar === 'message'" class="home-TUIKit-main">
              <div class="home-conversation">
                <TUISearch searchType="global"></TUISearch>
                <TUIConversation :isAdmin="isAdmin"></TUIConversation>
              </div>
              <div class="home-chat">
                <TUIChat>
                  <ChatDefaultContent></ChatDefaultContent>
                </TUIChat>
                <TUIGroup class="chat-aside"/>
                <TUISearch class="chat-aside" searchType="conversation"/>
              </div>
              <TUIContact display-type="selectFriend"/>
            </div>
            <div v-show="currentNavBar === 'relation'" class="home-TUIKit-main">
              <TUIContact
                  display-type="contactList"
                  @switchConversation="currentNavBar = 'message'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {ref, withDefaults, defineProps, defineEmits, vueVersion} from "../TUIKit/adapter-vue";
import {TUIStore, StoreName, TUIUserService} from "@tencentcloud/chat-uikit-engine";
import {TUICallKit} from "@tencentcloud/call-uikit-vue2";
import {TUIChat, TUIConversation, TUIContact, TUIGroup, TUISearch, genTestUserSig} from "../TUIKit";
import Header from "../components/Header.vue";
import NavBar from "../components/NavBar.vue";
import Profile from "./Profile.vue";
import ChatDefaultContent from "../components/ChatDefaultContent.vue";
import Drag from "../TUIKit/components/common/Drag";
import {isPC, isH5} from "../TUIKit/utils/env";
import {enableSampleTaskStatus} from "../TUIKit/utils/enableSampleTaskStatus";
import {TUILogin} from "@tencentcloud/tui-core";
import router, {useRoute} from "@/router";
import {Message} from "element-ui";
import {SDKAppID, secretKey} from "@/main";
import SearchMore from "@/TUIKit/components/TUISearch/search-more/index.vue";

const props = withDefaults(
    defineProps<{
      language: string;
    }>(),
    {
      language: "zh",
    }
);
const emits = defineEmits(["changeLanguage"]);
const locale = ref<string>(props.language);
const isMenuShow = ref<boolean>(true);
const currentNavBar = ref<string>("message");
const currentConversationID = ref<string>("");
const isAdmin = ref<boolean>(false);
const isCalling = ref<boolean>(false);
const isMinimized = ref<boolean>(false);
const dragRef = ref<typeof Drag>();
const isSettingShow = ref<boolean>(false);

// changeLanguage: 语言切换
function changeLanguage(language: string) {
  emits("changeLanguage", language);
}

TUIStore.watch(StoreName.CONV, {
  currentConversationID: (id: string) => {
    currentConversationID.value = id;
  },
});

function toggleMenu(value: boolean) {
  isMenuShow.value = value;
}

// beforeCalling: 通话前执行
function beforeCalling() {
  isCalling.value = true;
  enableSampleTaskStatus("call");
}

// afterCalling: 通话后执行
function afterCalling() {
  isCalling.value = false;
  isMinimized.value = false;
}

// onMinimized：音视频通话组件切换最小化状态时执行
function onMinimized(oldMinimizedStatus: boolean, newMinimizedStatus: boolean) {
  isMinimized.value = newMinimizedStatus;
  dragRef?.value?.positionReset();
}

function toggleSettingShow(value: boolean) {
  isSettingShow.value = value;
}

function switchNavBar(value: string) {
  currentNavBar.value = value;
}

const route = useRoute()
let id = route.query.id;
if (id) {
  localStorage.setItem("MYTUIKit-userID", id)
} else {
  id = localStorage.getItem("MYTUIKit-userID");
}

if (id) {
  const userID = id;
  let userSig = localStorage.getItem("MYTUIKit-userSig-" + userID) || "";
  // eslint-disable-next-line no-empty
  if (userSig) {
  } else {
    const options = genTestUserSig({
      SDKAppID,
      secretKey,
      userID: userID,
    });
    userSig = options.userSig
    localStorage.setItem("MYTUIKit-userSig-" + userID, options.userSig)
  }

  if (userID === 'superadmin') {
    isAdmin.value = true
  }

  const loginInfo: any = {
    SDKAppID,
    userID: userID,
    userSig: userSig,
    useUploadPlugin: true,
    useProfanityFilterPlugin: false,
    framework: `vue${vueVersion}`,
  };
  TUILogin.login(loginInfo)
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .then((res: any) => {
        let nickname = route.query.nickname;
        if (nickname) {
          // 更新自己的昵称
          let promise = TUIUserService.updateMyProfile({
            nick: nickname
          });
          promise.catch((error) => {
            // 调用异常时业务侧可以通过 promise.catch 捕获异常进行错误处理
          });
        }
      })
      .catch((error: any) => {
        Message.error({
          message: error,
        });
      });
} else {
  router.replace({name: "login"});
}

</script>

<style lang="scss">
@import "../styles/home.scss";
</style>

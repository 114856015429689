import { render, staticRenderFns } from "./EditProfilePopup.vue?vue&type=template&id=57e33796&scoped=true"
import script from "./EditProfilePopup.vue?vue&type=script&setup=true&lang=ts"
export * from "./EditProfilePopup.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57e33796",
  null
  
)

export default component.exports
var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'tui-search-container',
    !_setup.isPC && 'tui-search-container-h5',
    _setup.isPC && `container-${_setup.props.popupPosition}`,
    `container-${_setup.props.searchType}`,
  ]},[_c('div',{class:[
      _setup.isPC && `tui-search-container-${_setup.props.popupPosition}`,
      !_setup.isPC && 'tui-search-container-h5-main',
    ]},[(_setup.props.searchType === 'conversation')?_c('div',{staticClass:"tui-search-header"},[_c('div',{staticClass:"tui-search-header-title"},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t("TUISearch.搜索会话内容"))+" ")]),_c('div',{staticClass:"tui-search-header-close",on:{"click":_setup.closeSearchContainer}},[_c(_setup.Icon,{attrs:{"file":_setup.closeDarkIcon,"width":"14px","height":"14px"}})],1)]):_vm._e(),_c('div',{staticClass:"tui-search-tabs"},_vm._l((_setup.searchTypeList),function(tabItem,tabKey){return _c('div',{key:tabKey,class:[
          'tui-search-tabs-item',
          _setup.currentSearchMessageType.key === tabItem.key && 'tui-search-tabs-item-selected',
        ],on:{"click":function($event){return _setup.selectSearchType(tabItem)}}},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t(`TUISearch.${tabItem.label}`))+" ")])}),0),_vm._t("input"),(_setup.isTimeTabsShow)?_c('div',{staticClass:"tui-search-time"},_vm._l((_setup.searchMessageTimeList),function(tabItem,tabKey){return _c('div',{key:tabKey,class:[
          'tui-search-time-item',
          _setup.currentSearchMessageTime.key === tabItem.key && 'tui-search-time-item-selected',
        ],on:{"click":function($event){return _setup.selectSearchTime(tabItem)}}},[(tabItem.key === 'all')?_c('div',{staticClass:"tui-search-time-item-picker"},[(!_setup.isDatePickerShow)?_c('div',{staticClass:"tui-search-time-item-all",on:{"click":function($event){$event.stopPropagation();return _setup.handleSelectAllTimeClicked.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t(`TUISearch.选择时间`) + ": " + _setup.TUITranslateService.t(`TUISearch.全部`))+" "),_c(_setup.Icon,{attrs:{"file":_setup.downArrowIcon,"width":"14px","height":"14px"}})],1):_vm._e(),_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[(_setup.isDatePickerShow)?_c(_setup.DatePicker,{attrs:{"type":"range","rangeTableType":_setup.datePickerRangeDisplayType},on:{"pick":_setup.pickTimePeriod}}):_vm._e()],1),(_setup.isDatePickerShow)?_c('div',{staticClass:"tui-search-time-item-close",on:{"click":_setup.clearTimePicker}},[_c(_setup.Icon,{staticClass:"icon",attrs:{"file":_setup.closeIcon,"width":"14px","height":"14px"}})],1):_vm._e()]):_c('div',[_vm._v(" "+_vm._s(_setup.TUITranslateService.t(`TUISearch.${tabItem.label}`))+" ")])])}),0):_vm._e(),_vm._t("result")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }